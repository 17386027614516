<template>
  <v-app id="app">
    <v-main class="">
        <router-view></router-view>
    </v-main>
    <!-- global snackbar for alerts -->
    <v-snackbar v-model="snackbar" timeout="2500" :color="snackbarColor"
    >{{ snackbarMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
import EventBus, { ACTIONS } from './events/index'

export default {
  data: () => ({
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    links: [
      {
        name: 'PV',
        path: '/pv'
      },
      {
        name: 'Gas',
        path: '/gas'
      }
    ],
    sheet: false
  }),
  created () {

  },
  methods: {

  },
  mounted () {
    EventBus.$on(ACTIONS.SNACKBAR, message => {
      this.snackbarMessage = message.message
      this.snackbarColor = message.color
      this.snackbar = true
    })
  }
}
</script>

<style lang="scss">
@import "src/style/variables";
@import "src/style/default";
</style>
